import React from "react";

const SearchTranscript = (props) => (
  <div className="srch-transcript">
    {/* {(props.precontext !== undefined) ?
      // <div className="sui-result__value"
      //   dangerouslySetInnerHTML={{
      //     __html: props.precontext.raw
      //   }} /> 
      <div className="sui-result__value">
        <div className="srch-ctx-pre"><p className="srch-ctx-p">
          {(props.spre !== undefined) ?
            <span>
              <span className="srch-ctx-name">{props.spre.raw}</span>
              <span>:&nbsp;</span>
            </span>
            : <span />
          }
          <span
            dangerouslySetInnerHTML={{
              __html: props.precontext.raw
            }}
          />
        </p></div>
      </div>
      : <div />
    } */}

    <div className="op-result-text">
      <div className="columns is-vcentered is-mobile">
        {/* <div className="column is-narrow is-hidden-mobile">
          <figure className="image is-24x24"> */}
        {/* <img className="is-rounded" alt="" src={require(`./img/${props.speaker.raw.toLowerCase()}.jpeg`)} /> */}
        {/* <img className="is-rounded" alt="" src={`/static/img/${props.speaker.raw.toLowerCase()}.jpg`} />
          </figure>
        </div> */}
        <div className="column">
          <span
            className="srch-name"
            dangerouslySetInnerHTML={{
              __html: props.speaker.raw,
            }}
          />
          <span>:&nbsp;</span>
          <span
            className="sui-result__value"
            dangerouslySetInnerHTML={{
              __html: props.context.snippet,
            }}
          />
        </div>
      </div>
    </div>

    {props.postcontext !== undefined ? (
      <div className="sui-result__value">
        <div className="srch-ctx-post">
          <p className="srch-ctx-p">
            {props.spost !== undefined ? (
              <span>
                <span className="srch-ctx-name">{props.spost.raw}</span>
                <span>:&nbsp;</span>
              </span>
            ) : (
              <span />
            )}
            <span
              dangerouslySetInnerHTML={{
                __html: props.postcontext.raw,
              }}
            />
          </p>
        </div>
      </div>
    ) : (
      <div />
    )}
    {props.date !== undefined ? (
      <div className="srch-transcript-date is-size-7 has-text-grey-lighter is-italic">
        {props.date.raw}
      </div>
    ) : (
      <div />
    )}
  </div>
);

export default SearchTranscript;
