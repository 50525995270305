import React from "react";
// import Collapsible from "./Collapsible";
import SearchTranscript from "./SearchTranscript";

import { toggleSearchAudio } from "./config/helper";

export default ({ result, titleField }) => (
  <div //href={result.url.raw}
    className="sui-result"
  >
    {/*(console.log(titleField))*/}
    <div className="sui-result__header">
      {result.tag.raw !== "transcript" ? (
        <a
          className="sui-result__title  sui-result__title-link is-size-6"
          href={result.url.raw}
          target="opSearchTab"
          rel="noopener noreferrer"
          // Snippeted results contain search term highlights with html and are
          // 100% safe and santitized, so we dangerously set them here
          dangerouslySetInnerHTML={{ __html: result.episode.raw }}
        />
      ) : (
        <a
          className="sui-result__title  sui-result__title-link is-size-6"
          href={result.url.raw}
          target="opSearchTab"
          rel="noopener noreferrer"
          // Snippeted results contain search term highlights with html and are
          // 100% safe and santitized, so we dangerously set them here
          //dangerouslySetInnerHTML={{ __html: result.line.snippet }}
          dangerouslySetInnerHTML={{ __html: result.episode.raw }}
        />
      )}
    </div>

    {result.tag.raw === "transcript" ? (
      <div className="sui-result__body">
        <div className="sui-result__details">
          <div className="columns is-mobile">
            <div className="column is-narrow">
              <div className="buttons">
                {result.aurl !== undefined ? (
                  <button
                    id={`srch-res-btn-${result.id.raw}`}
                    onClick={() =>
                      toggleSearchAudio(
                        result.episode.raw,
                        result.aurl.raw,
                        result.id.raw,
                        result.seconds.raw
                      )
                    }
                    className="button is-small is-rounded is-info is-light"
                  >
                    <span className="icon">
                      <i
                        id={`srch-res-icn-${result.id.raw}`}
                        className="fas fa-play"
                      ></i>
                    </span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: result.time.raw,
                      }}
                    />
                  </button>
                ) : (
                  <div />
                )}
                <a
                  href={result.url.raw}
                  target="opSearchTab"
                  className="button is-small is-success is-light"
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: "Transcript",
                    }}
                  />
                </a>
              </div>
            </div>
            <div className="column is-narrow">
              <figure className="image is-24x24">
                <img
                  className="is-rounded"
                  alt=""
                  src={`/static/img/${result.speaker.raw.toLowerCase()}.jpg`}
                />
              </figure>
            </div>
          </div>
          <div className="op-result-text-ctx">
            <SearchTranscript
              precontext={result.pre}
              spre={result.spre}
              spost={result.spost}
              context={result.line}
              postcontext={result.post}
              speaker={result.speaker}
              date={result.date}
            />
          </div>
        </div>
      </div>
    ) : (
      <div></div>
    )}

    {result.tag.raw === "audio-chapter" ? (
      <div className="sui-result__body">
        <div className="sui-result__details">
          <div className="columns is-mobile">
            <div className="column is-narrow">
              <div className="buttons">
                {result.aurl !== undefined ? (
                  <button
                    id={`srch-res-btn-${result.id.raw}`}
                    onClick={() =>
                      toggleSearchAudio(
                        result.episode.raw,
                        result.aurl.raw,
                        result.id.raw,
                        result.seconds.raw
                      )
                    }
                    className="button is-small is-rounded is-info is-light"
                  >
                    <span className="icon">
                      <i
                        id={`srch-res-icn-${result.id.raw}`}
                        className="fas fa-play"
                      ></i>
                    </span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: result.time.raw,
                      }}
                    />
                  </button>
                ) : (
                  <div />
                )}
                <a
                  href={result.url.raw}
                  target="opSearchTab"
                  className="button is-small is-success is-light"
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: "Transcript",
                    }}
                  />
                </a>
              </div>
            </div>
          </div>
          <div>
            <span
              className="srch-ac-name"
              dangerouslySetInnerHTML={{
                __html: "Audio Chapter",
              }}
            />
            <span>:&nbsp;</span>
            <span
              className="sui-result__value"
              dangerouslySetInnerHTML={{
                __html: result.line.snippet,
              }}
            />
          </div>
          {result.date !== undefined ? (
            <div className="srch-transcript-date is-size-7 has-text-grey-lighter is-italic">
              {result.date.raw}
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    ) : (
      <div></div>
    )}

    {result.tag.raw === "show-notes" ? (
      <div className="sui-result__body">
        <div className="sui-result__details">
          <div className="columns is-mobile">
            <div className="column is-narrow">
              <div className="buttons">
                {result.aurl !== undefined ? (
                  <button
                    id={`srch-res-btn-${result.id.raw}`}
                    onClick={() =>
                      toggleSearchAudio(
                        result.episode.raw,
                        result.aurl.raw,
                        result.id.raw,
                        result.seconds.raw
                      )
                    }
                    className="button is-small is-rounded is-info is-light"
                  >
                    <span className="icon">
                      <i
                        id={`srch-res-icn-${result.id.raw}`}
                        className="fas fa-play"
                      ></i>
                    </span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: "0:00",
                      }}
                    />
                  </button>
                ) : (
                  <div />
                )}
                <a
                  href={result.url.raw}
                  target="opSearchTab"
                  className="button is-small is-light"
                >
                  <span className="icon">
                    <i
                      id={`srch-res-icn-${result.id.raw}`}
                      className="fas fa-link"
                    ></i>
                  </span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: "Full Transcript",
                    }}
                  />
                </a>
              </div>
            </div>
          </div>
          <div>
            <span
              className="srch-ac-name"
              dangerouslySetInnerHTML={{
                __html: "Show Notes<br>",
              }}
            />
            <span
              className="sui-result__value"
              dangerouslySetInnerHTML={{
                __html: result.line.snippet,
              }}
            />
          </div>
          {result.date !== undefined ? (
            <div className="srch-transcript-date is-size-7 has-text-grey-lighter is-italic">
              {result.date.raw}
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    ) : (
      <div></div>
    )}

    {result.tag.raw === "episode-title" ? (
      <div className="sui-result__body">
        <div className="sui-result__details">
          <div className="columns is-mobile">
            <div className="column is-narrow">
              <div className="buttons">
                {result.aurl !== undefined ? (
                  <button
                    id={`srch-res-btn-${result.id.raw}`}
                    onClick={() =>
                      toggleSearchAudio(
                        result.episode.raw,
                        result.aurl.raw,
                        result.id.raw,
                        result.seconds.raw
                      )
                    }
                    className="button is-small is-rounded is-info is-light"
                  >
                    <span className="icon">
                      <i
                        id={`srch-res-icn-${result.id.raw}`}
                        className="fas fa-play"
                      ></i>
                    </span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: "0:00",
                      }}
                    />
                  </button>
                ) : (
                  <div />
                )}
                <a
                  href={result.url.raw}
                  target="opSearchTab"
                  className="button is-small is-light"
                >
                  <span className="icon">
                    <i
                      id={`srch-res-icn-${result.id.raw}`}
                      className="fas fa-link"
                    ></i>
                  </span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: "Full Transcript",
                    }}
                  />
                </a>
              </div>
            </div>
          </div>
          {/*<div>
            <span
              className="srch-ac-name"
              dangerouslySetInnerHTML={{
                __html: "Episode Title",
              }}
            />
            <span>:&nbsp;</span>
            <span
              className="sui-result__value"
              dangerouslySetInnerHTML={{
                __html: result.line.snippet,
              }}
            />
          </div> */}
          {result.date !== undefined ? (
            <div className="srch-transcript-date is-size-7 has-text-grey-lighter is-italic">
              {result.date.raw}
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    ) : (
      <div></div>
    )}
  </div>
);
